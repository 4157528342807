"use client";

import { ResponsiveAds } from "@costar-ui/news";
import { AdConfigs } from "@costar-ui/news";

export const HomepageHouseAdvertisements = (props: {
  houseAdConfigs: AdConfigs;
}) => {
  const { houseAdConfigs } = props;
  return (
    <section
      key="advertisements"
      automation-id="advertisements"
      className="flex items-center justify-center"
    >
      <div className="h-[50px] max-w-[320px] md:h-[90px] md:max-w-[728px] lg:h-[250px] lg:max-w-[300px]">
        <ResponsiveAds
          uniqueId="homepage-house"
          adConfigs={houseAdConfigs}
          adSpacing={12}
        />
      </div>
    </section>
  );
};

export const HomepageAdvertisements = (props: {
  homepageArticleAdConfigs: AdConfigs;
}) => {
  const { homepageArticleAdConfigs } = props;
  return (
    <section
      key="advertisements"
      automation-id="advertisements"
      className="flex items-center justify-center"
    >
      <div className="h-[50px] max-w-[320px] md:h-[90px] md:max-w-[728px] lg:h-full lg:max-w-[300px]">
        <ResponsiveAds adConfigs={homepageArticleAdConfigs} adSpacing={12} />
      </div>
    </section>
  );
};
